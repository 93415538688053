<template>
  <review-card
    v-if="filteredPotentialSpaces.length > 0"
    :key="`list-groupstatus-${expanded}`"
    title="Espaços Potenciais"
    :explanation="explanation"
  >
    <v-list-group
      v-for="(item, index) in filteredPotentialSpaces"
      :key="`list-group-par-${index}`"
      no-action
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item class="py-2 px-0 item-par">
          <v-list-item-content>
            <v-list-item-title class="body-2">
              {{
                item.hidePatient
                  ? `Item ${index + 1}`
                  : item.potentialSpace.name
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <div class="breakBeforeClass">
        <div class="ml-3 mt-2 body-2">
          <property-value
            property="Zona de resolução"
            :value="item.diagnoseResolutionZone.resolutionZone.name"
          />
          <tracking-impaction-point-view-sides
            :tracking-point="
              item.diagnoseResolutionZone.resolutionZone.trackingPoint
            "
            :impaction-point="
              item.diagnoseResolutionZone.resolutionZone.impactionPoint
            "
            :tracking-point-side="item.diagnoseResolutionZone.trackingPointSide"
            :impaction-point-side="
              item.diagnoseResolutionZone.impactionPointSide
            "
            class="grey"
          />
          <div class="d-flex flex-column grey lighten-3">
            <view-point
              label="Par trauma"
              :name="
                item.diagnoseResolutionZone.resolutionZone.impactionPoint.name
              "
              :image="
                item.diagnoseResolutionZone.resolutionZone.impactionPoint.image
              "
              :image-description="
                item.diagnoseResolutionZone.resolutionZone.impactionPoint
                  .imageDescription
              "
              :expand-image="true"
            />
            <div class="d-flex justify-center">
              <property-value
                property="Lado(s) selecionado(s)"
                :value="
                  item.diagnoseResolutionZone.parTraumaSide | formatPointSides
                "
              />
            </div>
          </div>
          <div class="mt-2"></div>
          <property-value
            property="Ponto afetado"
            :value="item.diagnoseResolutionZone.affectedSpotParTrauma"
          />

          <pares-simple-list :pares="item.relatedPares" class="mb-3" />

          <property-value
            property="Impactar em casa"
            :value="item.patientImpactAtHome | booleanToString"
          />

          <property-value
            property="Comentários ao paciente"
            :value="item.patientComments"
          />
        </div>
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  name: "TherapyReviewPotentialSpaces",
  props: {
    potentialSpaces: {
      type: Array,
      default: () => [],
    },
    explanation: {
      type: String,
      default: "",
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredPotentialSpaces() {
      return this.potentialSpaces;
    },
  },
};
</script>

<style></style>
